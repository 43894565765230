import { bounceVariants } from '@animations/variants';
import { Box, BoxProps } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { FC, PropsWithChildren, useEffect } from 'react';

const MotionBox = motion<BoxProps>(Box);

type Props = {
  trigger: boolean;
};

export const BounceAnimation: FC<PropsWithChildren<Props>> = ({
  children,
  trigger,
}) => {
  const controls = useAnimation();

  useEffect(() => {
    if (trigger) {
      controls.start('bounce');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return (
    <MotionBox
      display='inline-block'
      animate={controls}
      variants={bounceVariants}
    >
      {children}
    </MotionBox>
  );
};
