import { IContactForm } from '@api/contact';
import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { BounceAnimation } from './bounce-animation';

interface MessageCounterProps {
  control: Control<IContactForm>;
  max: number;
  min?: number;
}

export const MessageCounter: FC<MessageCounterProps> = ({
  control,
  max,
  min = 0,
}) => {
  const value = useWatch({
    name: 'message',
    defaultValue: '',
    control,
  });

  return (
    <Box textAlign='right' fontSize='0.8rem' color='grey'>
      <BounceAnimation trigger={value?.length >= min}>
        <Box color={value?.length >= min ? 'green.400' : 'inherit'}>
          {value?.length ?? 0}
        </Box>
      </BounceAnimation>{' '}
      / {max} {min && ` (minimum ${min} characters)`}
    </Box>
  );
};

MessageCounter.defaultProps = {
  min: 0,
};
