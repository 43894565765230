import { IContactForm, submitForm } from '@api/contact';
import { Box, Button, Input, Textarea, useToast } from '@chakra-ui/react';
import { FormControl } from '@components/shared/FormControl';
import { HoneyPot } from '@components/shared/honey-pot';
import { FC } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { MessageCounter } from './components/message-counter';
import { ContactFormSuccess } from './contact-form-success';

export const ContactForm: FC = () => {
  const toast = useToast();
  const { register, handleSubmit, control } = useForm<IContactForm>({
    mode: 'onSubmit',
  });
  const { isSubmitting, isSubmitSuccessful, errors, isDirty } = useFormState({
    control,
  });

  const onSubmit = async (data: IContactForm) => {
    if (!isDirty) {
      return;
    }

    const response = await submitForm(data);

    if (response.status === 200) {
      onSuccess();
    } else {
      onError();
      throw Error();
    }
  };

  const onSuccess = () => {
    toast({
      title: 'Contact form sent.',
      description: 'We will get in contact with you as soon as possible!',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const onError = () => {
    toast({
      title: 'Contact form has not been sent.',
      description:
        'Something went. Please try again later or contact us directly over email.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  if (isSubmitSuccessful) {
    return <ContactFormSuccess />;
  }

  return (
    <Box as='form' onSubmit={handleSubmit(onSubmit)} width='100%'>
      <FormControl
        label='Name'
        htmlFor='name'
        error={errors.name}
        disabled={isSubmitting || isSubmitSuccessful}
      >
        <Input
          id='name'
          {...register('name', {
            required: true,
          })}
          isRequired
        />
      </FormControl>

      <FormControl
        label='E-Mail'
        htmlFor='email'
        error={errors.email}
        disabled={isSubmitting || isSubmitSuccessful}
      >
        <Input
          id='email'
          type='email'
          {...register('email', {
            required: true,
          })}
          isRequired
        />
      </FormControl>

      <FormControl
        htmlFor='message'
        label='Message'
        error={errors.message}
        disabled={isSubmitting || isSubmitSuccessful}
      >
        <Textarea
          id='message'
          {...register('message', {
            required: true,
            minLength: 50,
            maxLength: 500,
          })}
          rows={5}
          resize='vertical'
          isRequired
          maxLength={500}
        />

        <MessageCounter max={500} min={50} control={control} />
      </FormControl>

      {/*  */}

      <HoneyPot
        {...register('telephone', {
          required: false,
        })}
      />

      <HoneyPot
        {...register('businessEmail', {
          required: false,
        })}
      />

      {/* END */}

      <Button
        isLoading={isSubmitting}
        disabled={isSubmitting || isSubmitSuccessful}
        type='submit'
        width='100%'
        marginTop='3rem'
        colorScheme='yellow'
        size='lg'
      >
        Contact us
      </Button>
    </Box>
  );
};
