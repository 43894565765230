import FadeIn, { FromDirection } from '@animations/FadeIn';
import {
  Box,
  Center,
  Container,
  Heading,
  LightMode,
  Text,
} from '@chakra-ui/react';
import { Card } from '@components/shared/Card';
import { ContactForm } from '@feature/contact';
import { SEO } from '@feature/seo';
import { FC } from 'react';
import { useInView } from 'react-intersection-observer';

export const Head = () => <SEO title='Contact' />;

const ContactPage: FC = () => {
  const [ref, inView] = useInView({ threshold: 0.5 });

  return (
    <>
      <SEO title='Contact' />

      <Box as='section' height='100vh'>
        <Center h='100%'>
          <Container>
            <Heading as='h1' mb='4'>
              Say Hello!
            </Heading>

            <Text fontSize='xl'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              eget est placerat, sollicitudin nulla quis, efficitur dolor. Cras
              in egestas purus. Ut nec odio ipsum. In turpis urna, tristique vel
              pretium non, aliquet ut magna. Orci varius natoque penatibus et
              magnis dis parturient montes, nascetur ridiculus mus.
            </Text>
          </Container>
        </Center>
      </Box>

      <Box as='section' height='100vh' ref={ref} color='black'>
        <Center h='100%'>
          <Container>
            <FadeIn from={FromDirection.Right} inView={inView}>
              <LightMode>
                <Card variant='rounded' bgColor='white'>
                  <Heading textAlign='center' marginBottom='2rem'>
                    Contact Us!
                  </Heading>
                  <ContactForm />
                </Card>
              </LightMode>
            </FadeIn>
          </Container>
        </Center>
      </Box>
    </>
  );
};

export default ContactPage;
