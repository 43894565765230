import { Box } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

export const HoneyPotLabel: FC<PropsWithChildren> = ({ children }) => (
  <Box
    as='label'
    opacity='0'
    position='absolute'
    top='0'
    left='0'
    height='0'
    width='0'
    zIndex='-1'
  >
    {children}
  </Box>
);
