import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
} from '@chakra-ui/react';
import { Link } from 'gatsby';

export const ContactFormSuccess = () => (
  <>
    <Alert
      status='success'
      variant='subtle'
      flexDirection='column'
      placeItems='center'
      textAlign='center'
      p='5'
      borderRadius='5px'
    >
      <AlertIcon boxSize='40px' mr={0} />
      <AlertTitle mt={4} mb={4} fontSize='lg'>
        Thank you!
      </AlertTitle>
      <AlertDescription maxWidth='sm'>
        Your form was submitted successfully. <br />
        We will get in touch with you soon.
      </AlertDescription>
    </Alert>

    <Link to='/'>
      <Button width='100%' marginTop='3rem' colorScheme='yellow' size='lg'>
        Go back to home
      </Button>
    </Link>
  </>
);
