import { forwardRef, InputHTMLAttributes, Ref } from 'react';
import { HoneyPotLabel } from './honey-pot-label';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

export const HoneyPot = forwardRef(
  (props: Props, ref: Ref<HTMLInputElement>) => (
    <HoneyPotLabel>
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        autoComplete='off'
        type='text'
        name={props.name}
        id={props.name}
        ref={ref}
      />
    </HoneyPotLabel>
  )
);
